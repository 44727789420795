import React, { lazy } from "react";

const PayinLayout = lazy(() => import("components/pages/Payin/PayinLayout"));
const Home = lazy(() => import("components/pages/Payin/Home/Home"));
const Tenant = lazy(() => import("components/pages/Payin/Home/Tenant"));
const MerchantAgregatorEditor = lazy(() => import("components/pages/Payin/MerchantAgregators/MerchantAgregatorEditor/MerchantAgregatorEditor"));
const MerchantAgregatorList = lazy(() => import("components/pages/Payin/MerchantAgregators/MerchantAgregatorList/MerchantAgregatorList"));
const CalculationEditor = lazy(() => import("components/pages/Payin/Finance/Calculations/CalculationEditor/CalculationEditor"));
const CalculationList = lazy(() => import("components/pages/Payin/Finance/Calculations/CalculationList/CalculationList"));
const CountryList = lazy(() => import("components/pages/Payin/Util/Country/CountryList/CountryList"));
const CountryEditor = lazy(() => import("components/pages/Payin/Util/Country/CountryEditor/CountryEditor"));
const StateList = lazy(() => import("components/pages/Payin/Util/State/StateList/StateList"));
const StateEditor = lazy(() => import("components/pages/Payin/Util/State/StateEditor/StateEditor"));
const CityList = lazy(() => import("components/pages/Payin/Util/City/CityList/CityList"));
const CityEditor = lazy(() => import("components/pages/Payin/Util/City/CityEditor/CityEditor"));
const CurrencyList = lazy(() => import("components/pages/Payin/Util/Currency/CurrencyList/CurrencyList"));
const CurrencyEditor = lazy(() => import("components/pages/Payin/Util/Currency/CurrencyEditor/CurrencyEditor"));
const TransactionList = lazy(() => import("components/pages/Payin/Operation/Transaction/TransactionList/TransactionList"));
const RateBaseList = lazy(() => import("components/pages/Payin/Finance/RateBase/RateBaseList/RateBaseList"));
const RateBaseEditor = lazy(() => import("components/pages/Payin/Finance/RateBase/RateBaseEditor/RateBaseEditor"));
const RateLocalList = lazy(() => import("components/pages/Payin/Finance/RateLocal/RateLocalList/RateLocalList"));
const RateLocalEditor = lazy(() => import("components/pages/Payin/Finance/RateLocal/RateLocalEditor/RateLocalEditor"));
const DataSourceList = lazy(() => import("components/pages/Payin/Util/DataSource/DataSourceList/DataSourceList"));
const DataSourceEditor = lazy(() => import("components/pages/Payin/Util/DataSource/DasaSourceEditor/DataSourceEditor"));
const CommissionsListData = lazy(() => import("components/pages/Payin/Finance/Commissions/CommissionsList/ComissionsListData"));
const CommissionsEditor = lazy(() => import("components/pages/Payin/Finance/Commissions/CommissionsEditor/CommisionsEditor"));
const UserList = lazy(() => import("components/pages/Payin/Users/UserList/UserList"));
const UserEditor = lazy(() => import("components/pages/Payin/Users/UserEditor/UserEditor"));
const TaxList = lazy(() => import("components/pages/Payin/Finance/Tax/TaxList/TaxList"));
const TaxEditor = lazy(() => import("components/pages/Payin/Finance/Tax/TaxEditor/TaxEditor"));
const TableMappingList = lazy(() => import("components/pages/Payin/System/TableMapping/TableMappingList/TableMappingList"));
const TableMappingEditor = lazy(() => import("components/pages/Payin/System/TableMapping/TableMappingEditor/TableMappingEditor"));
const CheckPaymentList = lazy(() => import("components/pages/Payin/Payment/CheckPaymentList/CheckPaymentList"));
const RequestPayment = lazy(() => import("components/pages/Payin/Payment/RequestPayment/RequestPayment"));

var routes = [
  {
    path: "/payin",
    name: "Payin",
    icon: "",
    component: PayinLayout,
    layout: "/payin",
    showInSidebar: false,
  },
  {
    path: "/home",
    name: "Home",
    title: "Home",
    icon: "nc-icon nc-bank",
    component: Home,
    layout: "/payin",
    showInSidebar: false,
  },
  {
    path: "/home/:tenant",
    name: "Tenant",
    title: "Tenant",
    icon: "nc-icon nc-bank",
    component: Tenant,
    layout: "/payin",
    showInSidebar: false,
  },
  {
    // Finanzas
    name: "Finance",
    icon: "nc-icon nc-money-coins",
    showInSidebar: true,
    scopes: [
      "payinBackoffice_admin_access",
      "payinBackoffice_finance_access",
      "payinBackoffice_calculation_access",
      "payinBackoffice_rateBase_access",
      "payinBackoffice_commission_access",
      "payinBackoffice_rateLocal_access",
      "payinBackoffice_tax_access",
    ],
    roles: [
      // "BACKOFFICE_PAYIN_CALCULATION_EDITOR",
      // "BACKOFFICE_PAYIN_CALCULATION_LIST",
      // "BACKOFFICE_PAYIN_COMMISSION_EDITOR",
      // "BACKOFFICE_PAYIN_COMMISSION_LIST",
      // "BACKOFFICE_PAYIN_RATE_BASE_EDITOR",
      // "BACKOFFICE_PAYIN_RATE_BASE_LIST",
      // "BACKOFFICE_PAYIN_RATE_LOCAL_EDITOR",
      // "BACKOFFICE_PAYIN_RATE_LOCAL_LIST",
      // "BACKOFFICE_PAYIN_TAX_EDITOR",
      // "BACKOFFICE_PAYIN_TAX_LIST",
    ],
    childrens: [
      // Calculation
      {
        path: "/calculation/list",
        name: "Calculations",
        title: "Calculation List",
        icon: "nc-icon fas fa-calculator",
        component: CalculationList,
        layout: "/payin",
        showInSidebar: true,
        scopes: ["payinBackoffice_admin_access", "payinBackoffice_finance_access", "payinBackoffice_calculation_access"],
        // roles: ["BACKOFFICE_PAYIN_CALCULATION_LIST"],
      },
      {
        path: "/calculation/editor",
        name: "Calculation Editor",
        title: "Calculation Editor",
        icon: "nc-icon fas fa-calculator",
        component: CalculationEditor,
        layout: "/payin",
        showInSidebar: false,
        scopes: [],
        // roles: ["BACKOFFICE_PAYIN_CALCULATION_EDITOR"],
      },
      {
        path: "/calculation/editor/:id",
        name: "Calculation Editor",
        title: "Agregar / editar Calculo",
        icon: "nc-icon fas fa-calculator",
        component: CalculationEditor,
        layout: "/payin",
        showInSidebar: false,
        scopes: [],
        // roles: ["BACKOFFICE_PAYIN_CALCULATION_EDITOR"],
      },
      // RateBase
      {
        path: "/ratebase/list",
        name: "Rates Base",
        title: "Rate Base List",
        icon: "nc-icon fas fa-chart-line",
        component: RateBaseList,
        layout: "/payin",
        showInSidebar: true,
        scopes: ["payinBackoffice_admin_access", "payinBackoffice_finance_access", "payinBackoffice_rateBase_access"],
        // roles: ["BACKOFFICE_PAYIN_RATE_BASE_LIST"],
      },
      {
        path: "/ratebase/editor",
        name: "Rate Base Editor",
        title: "Rate Base Editor",
        icon: "nc-icon fas fa-chart-line",
        component: RateBaseEditor,
        layout: "/payin",
        showInSidebar: false,
        scopes: [],
        // roles: ["BACKOFFICE_PAYIN_COMMISSION_EDITOR"],
      },
      {
        path: "/ratebase/editor/:currencyId",
        name: "Rate Base Editor",
        title: "Rate Base Editor",
        icon: "nc-icon fas fa-chart-line",
        component: RateBaseEditor,
        layout: "/payin",
        showInSidebar: false,
        scopes: [],
        // roles: ["BACKOFFICE_PAYIN_RATE_BASE_EDITOR"],
      },
      //Comisiones
      {
        path: "/commissions/list",
        name: "Comissions",
        title: "Comission List",
        icon: "nc-icon nc-chart-pie-36",
        component: CommissionsListData,
        layout: "/payin",
        showInSidebar: true,
        scopes: ["payinBackoffice_admin_access", "payinBackoffice_finance_access", "payinBackoffice_commission_access"],
        // roles: ["BACKOFFICE_PAYIN_COMMISSION_LIST"],
      },
      {
        path: "/commissions/editor",
        name: "Comission Editor",
        title: "Comission Editor",
        icon: "nc-icon fas fa-chart-line",
        component: CommissionsEditor,
        layout: "/payin",
        showInSidebar: false,
        scopes: [],
        // roles: ["BACKOFFICE_PAYIN_COMMISSION_EDITOR"],
      },
      {
        path: "/commissions/editor/:commissionId",
        name: "Comission Editor",
        title: "Comission Editor",
        icon: "nc-icon fas fa-chart-line",
        component: CommissionsEditor,
        layout: "/payin",
        showInSidebar: false,
        scopes: [],
        // roles: ["BACKOFFICE_PAYIN_COMMISSION_EDITOR"],
      },
      // RateLocal
      {
        path: "/ratelocal/list",
        name: "Rates Local",
        title: "Rate Local List",
        icon: "nc-icon fas fa-chart-bar",
        component: RateLocalList,
        layout: "/payin",
        showInSidebar: true,
        scopes: ["payinBackoffice_admin_access", "payinBackoffice_finance_access", "payinBackoffice_rateLocal_access"],
        // roles: ["BACKOFFICE_PAYIN_RATE_LOCAL_LIST"],
      },
      {
        path: "/ratelocal/editor",
        name: "Rate Local Editor",
        title: "Rate Local Editor",
        icon: "nc-icon fas fa-chart-bar",
        component: RateLocalEditor,
        layout: "/payin",
        showInSidebar: false,
        scopes: [],
        // roles: ["BACKOFFICE_PAYIN_RATE_LOCAL_EDITOR"],
      },
      // Tax
      {
        path: "/tax/list",
        name: "Taxes",
        title: "Tax List",
        icon: "nc-icon fas fa-hand-holding-usd",
        component: TaxList,
        layout: "/payin",
        showInSidebar: true,
        scopes: ["payinBackoffice_admin_access", "payinBackoffice_finance_access", "payinBackoffice_tax_access"],
        // roles: ["BACKOFFICE_PAYIN_TAX_LIST"],
      },
      {
        path: "/tax/editor",
        name: "Tax Editor",
        title: "Tax Editor",
        icon: "nc-icon fas fa-hand-holding-usd",
        component: TaxEditor,
        layout: "/payin",
        showInSidebar: false,
        scopes: [],
        // roles: ["BACKOFFICE_PAYIN_TAX_EDITOR"],
      },
      {
        path: "/tax/editor/:taxId",
        name: "Tax Editor",
        title: "Tax Editor",
        icon: "nc-icon fas fa-hand-holding-usd",
        component: TaxEditor,
        layout: "/payin",
        showInSidebar: false,
        scopes: [],
        // roles: ["BACKOFFICE_PAYIN_TAX_EDITOR"],
      },
    ],
  },
  {
    //Comercial
    name: "Comercial",
    icon: "nc-icon fas fa-industry",
    showInSidebar: true,
    scopes: ["payinBackoffice_admin_access", "payinBackoffice_commercial_access"],
    // roles: ["BACKOFFICE_PAYIN_MERCHANT_AGGREGATOR_LIST", "BACKOFFICE_PAYIN_MERCHANT_AGGREGATOR_EDITOR"],
    childrens: [
      {
        path: "/merchant-aggregator/list",
        name: "Merchants & Aggregators",
        title: "Merchant & Aggregator List",
        icon: "nc-icon nc-single-02",
        component: MerchantAgregatorList,
        layout: "/payin",
        showInSidebar: true,
        scopes: [],
        // roles: ["BACKOFFICE_PAYIN_MERCHANT_AGGREGATOR_LIST"],
      },
      {
        path: "/merchant-aggregator/editor",
        name: "Merchants & Aggregators Editor",
        title: "Merchants & Aggregators Editor",
        icon: "nc-icon nc-single-02",
        component: MerchantAgregatorEditor,
        layout: "/payin",
        showInSidebar: false,
        // scopes: [],
        // roles: ["BACKOFFICE_PAYIN_MERCHANT_AGGREGATOR_EDITOR"],
      },
      {
        path: "/merchant-aggregator/editor/:id",
        name: "Merchants & Aggregators Editor",
        title: "Merchants & Aggregators Editor",
        icon: "nc-icon nc-single-02",
        component: MerchantAgregatorEditor,
        layout: "/payin",
        showInSidebar: false,
        scopes: [],
        // roles: ["BACKOFFICE_PAYIN_MERCHANT_AGGREGATOR_EDITOR"],
      },
    ],
  },
  {
    //Operaciones
    name: "Operations",
    icon: "nc-icon nc-globe",
    showInSidebar: true,
    scopes: ["payinBackoffice_admin_access", "payinBackoffice_operations_access"],
    // roles: ["BACKOFFICE_PAYIN_TRANSACTION_LIST"],
    childrens: [
      {
        path: "/transactions/list",
        name: "Transactions",
        title: "Transaction List",
        icon: "nc-icon nc-bullet-list-67",
        component: TransactionList,
        layout: "/payin",
        showInSidebar: true,
        scopes: [],
        // roles: ["BACKOFFICE_PAYIN_TRANSACTION_LIST"],
      },
    ],
  },
  {
    //Utiles
    name: "Utils",
    title: "Utils",
    icon: "nc-icon nc-simple-add",
    showInSidebar: true,
    scopes: ["payinBackoffice_admin_access", "payinBackoffice_utils_access"],
    // roles: ["BACKOFFICE_PAYIN_UTILS"],
    childrens: [
      // Paises
      {
        path: "/tools/country/list",
        name: "Countries",
        title: "Country List",
        icon: "nc-icon nc-world-2",
        component: CountryList,
        layout: "/payin",
        showInSidebar: true,
      },
      {
        path: "/tools/country/editor",
        name: "Country Editor",
        title: "Country Editor",
        icon: "nc-icon nc-world-2",
        component: CountryEditor,
        layout: "/payin",
        showInSidebar: false,
      },
      {
        path: "/tools/country/editor/:id",
        name: "Country Editor",
        title: "Country Editor",
        icon: "nc-icon nc-world-2",
        component: CountryEditor,
        layout: "/payin",
        showInSidebar: false,
      },
      // Statuss / Provincias
      {
        path: "/tools/state/list",
        name: "States",
        title: "State List",
        icon: "nc-icon nc-world-2",
        component: StateList,
        layout: "/payin",
        showInSidebar: true,
      },
      {
        path: "/tools/state/editor",
        name: "State Editor",
        title: "State Editor",
        icon: "nc-icon nc-world-2",
        component: StateEditor,
        layout: "/payin",
        showInSidebar: false,
      },
      {
        path: "/tools/state/editor/:stateId/:countryId",
        name: "State Editor",
        title: "State Editor",
        icon: "nc-icon nc-world-2",
        component: StateEditor,
        layout: "/payin",
        showInSidebar: false,
      },
      // Ciudades
      {
        path: "/tools/city/list",
        name: "Cities",
        title: "City List",
        icon: "nc-icon nc-world-2",
        component: CityList,
        layout: "/payin",
        showInSidebar: true,
      },
      {
        path: "/tools/city/editor",
        name: "City Editor",
        title: "City Editor",
        icon: "nc-icon nc-world-2",
        component: CityEditor,
        layout: "/payin",
        showInSidebar: false,
      },
      {
        path: "/tools/city/editor/:cityId/:countryId",
        name: "City Editor",
        title: "City Editor",
        icon: "nc-icon nc-world-2",
        component: CityEditor,
        layout: "/payin",
        showInSidebar: false,
      },
      // Monedas
      {
        path: "/tools/currency/list",
        name: "Currencies",
        title: "Currency List",
        icon: "nc-icon nc-money-coins",
        component: CurrencyList,
        layout: "/payin",
        showInSidebar: true,
      },
      {
        path: "/tools/currency/editor",
        name: "Currency Editor",
        title: "Currency Editor",
        icon: "nc-icon nc-world-2",
        component: CurrencyEditor,
        layout: "/payin",
        showInSidebar: false,
      },
      {
        path: "/tools/currency/editor/:id",
        name: "Currency Editor",
        title: "Currency Editor",
        icon: "nc-icon nc-world-2",
        component: CurrencyEditor,
        layout: "/payin",
        showInSidebar: false,
      },
      // Data source
      {
        path: "/tools/datasource/list",
        name: "Data Source",
        title: "Data Source List",
        icon: "nc-icon fas fa-tools",
        component: DataSourceList,
        layout: "/payin",
        showInSidebar: true,
      },
      {
        path: "/tools/datasource/editor",
        name: "Data Source Editor",
        title: "Data Source Editor",
        icon: "nc-icon nc-world-2",
        component: DataSourceEditor,
        layout: "/payin",
        showInSidebar: false,
      },
      {
        path: "/tools/datasource/editor/:id",
        name: "Data Source Editor",
        title: "Data Source Editor",
        icon: "nc-icon nc-world-2",
        component: DataSourceEditor,
        layout: "/payin",
        showInSidebar: false,
      },
    ],
  },
  {
    // Usuarios
    name: "Users",
    icon: "nc-icon nc-single-02",
    showInSidebar: true,
    scopes: ["payinBackoffice_admin_access", "payinBackoffice_users_access"],
    // roles: ["BACKOFFICE_PAYIN_USER_LIST", "BACKOFFICE_PAYIN_USER_EDITOR"],
    childrens: [
      {
        path: "/users/list",
        name: "Users",
        title: "User List",
        icon: "nc-icon nc-bullet-list-67",
        component: UserList,
        layout: "/payin",
        showInSidebar: true,
        scopes: [],
        // roles: ["BACKOFFICE_PAYIN_USER_LIST"],
      },
      {
        path: "/users/editor",
        name: "User Editor",
        title: "User Editor",
        icon: "nc-icon nc-bullet-list-67",
        component: UserEditor,
        layout: "/payin",
        showInSidebar: false,
        scopes: [],
        // roles: ["BACKOFFICE_PAYIN_USER_EDITOR"],
      },
      {
        path: "/users/editor/:id",
        name: "User Editor",
        title: "User Editor",
        icon: "nc-icon nc-bullet-list-67",
        component: UserEditor,
        layout: "/payin",
        showInSidebar: false,
        scopes: [],
        // roles: ["BACKOFFICE_PAYIN_USER_EDITOR"],
      },
    ],
  },
  {
    // Sistemas
    name: "System",
    icon: "nc-icon nc-laptop",
    showInSidebar: true,
    scopes: ["payinBackoffice_admin_access", "payinBackoffice_system_access"],
    // roles: ["BACKOFFICE_PAYIN_TABLE_MAPPING_LIST", "BACKOFFICE_PAYIN_TABLE_MAPPING_EDITOR"],
    childrens: [
      {
        path: "/tablemapping/list",
        name: "Table Mapping",
        title: "Table Mapping List",
        icon: "nc-icon nc-laptop",
        component: TableMappingList,
        layout: "/payin",
        // roles: ["BACKOFFICE_PAYIN_TABLE_MAPPING_LIST"],
        showInSidebar: true,
      },
      {
        path: "/tablemapping/editor/:entity",
        name: "Table Mapping Editor",
        title: "Table Mapping Editor",
        icon: "nc-icon nc-laptop",
        component: TableMappingEditor,
        layout: "/payin",
        // roles: ["BACKOFFICE_PAYIN_TABLE_MAPPING_EDITOR"],
        showInSidebar: false,
      },
    ],
  },
  {
    // Payments
    name: "Payments",
    icon: "nc-icon fas fa-money-bill-wave",
    showInSidebar: true,
    scopes: ["payinBackoffice_admin_access", "payinBackoffice_payments_access", "payinBackoffice_checkPayments_access", "payinBackoffice_requestPayments_access"],
    // roles: ["BACKOFFICE_PAYIN_PAYMENT_EDITOR", "BACKOFFICE_PAYIN_PAYMENT_LIST"],
    childrens: [
      {
        path: "/payments/list",
        name: "Check Payments",
        title: "Check Payments",
        icon: "nc-icon fas fa-tasks",
        component: CheckPaymentList,
        layout: "/payin",
        showInSidebar: true,
        scopes: ["payinBackoffice_admin_access", "payinBackoffice_payments_access", "payinBackoffice_checkPayments_access"],
        // roles: ["BACKOFFICE_PAYIN_PAYMENT_LIST"],
      },
      {
        path: "/payments/editor",
        name: "Request Payment",
        title: "Request Payment",
        icon: "nc-icon fas fa-money-bill-wave",
        component: RequestPayment,
        layout: "/payin",
        showInSidebar: true,
        scopes: ["payinBackoffice_admin_access", "payinBackoffice_payments_access", "payinBackoffice_requestPayments_access"],
        // roles: ["BACKOFFICE_PAYIN_PAYMENT_EDITOR"],
      },
    ],
  },
];
export default routes;
